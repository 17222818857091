import styled from 'styled-components';
import { atMinWidth } from '../../../styles/atoms/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  ${atMinWidth.sm`
     gap: 48px;
  `}

  ${atMinWidth.lg`
     gap: 64px;
  `}

  .cta-container {
    display: flex;
    justify-content: center;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${atMinWidth.md`
      flex-direction: row;
      flex-wrap: wrap;

      gap: 40px 30px;

      > * {
        flex: 0 0 calc((100% - 30px) / 2);
      }
  `}

  ${atMinWidth.lg`
      justify-content: center;

      &.align-left {
        justify-content: flex-start;
      }

      > * {
        flex: 0 0 calc((100% - 60px) / 3);
      }
   `}
`;
